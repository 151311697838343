<template>
  <div>
    <div id="container"></div>
    <el-dialog
      title="监控视频"
      :visible.sync="monitorPupShow"
      width="760px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="monitorPupShowCloseCallBack"
    >
      <div class="player_wrapper" v-if="showPlayer">
        <EZUIKitPlayer :url="playUrl" :atoken="_AccessToken" />
      </div>
      <div class="dev_msg_box" v-else>
        {{ dev_msg }}
      </div>
    </el-dialog>
  </div>
</template>

<script>
import a0 from "../../assets/0.png";
import a21 from "../../assets/2,1.png";
import a31 from "../../assets/3,1.png";
import a32 from "../../assets/3,2.png";
import a43 from "../../assets/4,3.png";
import a42 from "../../assets/4,2.png";
import a41 from "../../assets/4,1.png";
import a10 from "../../assets/10.png";
import a100 from "../../assets/100.png";
import lvse from "../../assets/lvse.png";
import hongse from "../../assets/hongse.png";
import hongse_2 from "../../assets/hongse_2.png";

import huangse from "../../assets/huangse.png";

import sashuiche from "../../assets/sashuiche1.png";
import lajiche from "../../assets/lajiche1.png";
import carred from "../../assets/carred.png";
import cargrey from "../../assets/cargrey.png";
import cargreen from "../../assets/cargreen.png";
import AMapLoader from "@amap/amap-jsapi-loader";
import InformationFormBgc from "@/assets/InformationFormBgc.png";
import InformationFormBgcBig from "@/assets/InformationFormBgcBig.png";
import shexiangtou from "@/assets/shexiangtou.png";
import {
  GetHomePageMain,
  addEditPoint,
  proxyRequest,
  getYunAccessToken,
  getCarLocationInfo,
} from "@/request/api";
import EZUIKitPlayer from "@/components/EZUIKitPlayer.vue";
import axios from "axios";
window._AMapSecurityConfig = {
  securityJsCode: "380be16e67ca78c3725692b21427d46d", // 开发者密钥
};
export default {
  components: {
    EZUIKitPlayer,
  },
  data() {
    return {
      map: null,
      list: [],
      infoWindow: null,
      markerarr: [],
      numarr: [[a0], [], [], [], [], []],
      time: null,
      width1: null,
      address: "",
      carLsit: [],
      _AccessToken: "",
      playUrl: "",
      dev_msg: "设备不在线",
      showPlayer: false,
      monitorPupShow: false,
    };
  },
  async mounted() {
    window.addEventListener("mousewheel", this.getzoom);
    const el = document.querySelector("#index");
    this.width1 = el.offsetWidth;
    let res = await GetHomePageMain();
    this.list = res.data.point_list;
    let res1 = await proxyRequest({
      path: "Transfer/transferLogin",
      data: JSON.stringify({
        userKey: "c814b94b3bddad4eb49d751698fb302c",
        password: "c6ec7f16f151143b3cdef9b2b1f01741",
        tfKey: "f35d31a6dad1eded2364682290a0d437",
      }),
    });

    if (res1.errorStr == "操作成功") {
      sessionStorage.setItem("cartoken", res1.results.token);
    } else {
    }

    this.getCarList();

    //地图初始化
    let _this = this;
    window.window.f5cc == null;
    window.f5cc = setInterval(async () => {
      GetHomePageMain().then((res) => {
        _this.setMarkerItemIcon(res.data.point_list);
      });
    }, 30000);
  },
  methods: {
    monitorPupShowCloseCallBack() {
      this.showPlayer = false;
    },

    async getPlayerUrl(_terminal_code) {
      const apiClient = axios.create();
      const res = await apiClient({
        url: "https://open.ys7.com/api/lapp/v2/live/address/get",
        method: "post",
        data: {
          accessToken: this._AccessToken,
          deviceSerial: _terminal_code,
          code: "boyataoHB",
        },
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      // console.log("--------------res-------------->", res);
      if (res.data.code == 200) {
        this.$data.showPlayer = true;
        this.$data.playUrl = res.data.data.url;

        // console.log("this.$data.playUrl", this.$data.playUrl);
      } else {
        this.$data.dev_msg = res.data.msg;
      }
    },
    async getCarList() {
      const res = await getCarLocationInfo();

      this.carLsit = res.data.results;
      this.initMap();
    },

    setMarkerItemIcon(list) {
      this.markerarr.forEach((markerItem, markerIndex) => {
        let width1;
        let height1;

        let _imgsrc = lvse;

        let size;
        if (this.width1 > 7000) {
          width1 = 70;
          height1 = 70;
          size = 24;
        } else {
          width1 = 35;
          height1 = 35;
          size = 12;
        }

        if (list[markerIndex].hand_movement == 1) {
          // 绿色
          if ([markerIndex].point_full_status == 1) {
            _imgsrc = lvse;
          }

          // 黄色
          if (list[markerIndex].point_full_status == 2) {
            _imgsrc = huangse;
          }
          // 红色
          if (list[markerIndex].point_full_status == 3) {
            _imgsrc = hongse;
          }
        } else {
          // 红色
          if (list[markerIndex].barrel_full_info.barrel_full > 0) {
            _imgsrc = hongse;
          } // 黄色
          else if (list[markerIndex].barrel_full_info.barrel_no_full > 0) {
            _imgsrc = huangse;
          }

          // 绿色
          else if (list[markerIndex].barrel_full_info.barrel_no_full == 0) {
            _imgsrc = lvse;
          }
        }
        if (list[i].temperature_warning == 1) _imgsrc = hongse_2;
        const _setContent = `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${_imgsrc}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;font-weight: 700;text-align: center;">${list[markerIndex].point_name}</p>`;

        markerItem.setContent(_setContent);
      });
    },

    initMap() {
      AMapLoader.load({
        key: "b7c4488941d2e660802ee0ac4e010df2", //开发者Key
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [""], // 需要使用的的插件列表
      })
        .then((AMap) => {
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 14, //初始化地图级别
            center: [111.30444444444444, 23.319166666666668], //初始化地图中心点位置
            mapStyle: "amap://styles/a2363c2d5f00c83574cb1f73783a1fcd", //样式id
          });
          //点击地图关闭信息窗体
          this.map.on("click", this.closeinformation);
          //循环遍历生成点标记
          let width1;
          let height1;

          let _imgsrc = lvse;

          let size;
          if (this.width1 > 7000) {
            width1 = 70;
            height1 = 70;
            size = 24;
          } else {
            width1 = 35;
            height1 = 35;
            size = 12;
          }
          if (this.carLsit.length > 0) {
            for (let i = 0; i < this.carLsit.length; i++) {
              if (this.carLsit[i].isConn) {
                if (this.carLsit[i].accStatus == 1) {
                  let marker = new AMap.Marker({
                    icon: new AMap.Icon({
                      image: cargreen,
                      imageSize: new AMap.Size(25, 53),
                    }),
                    position: [this.carLsit[i].lng, this.carLsit[i].lat],
                    draggable: false,
                    alt: this.carLsit[i].deviceId,
                    angle: this.carLsit[i].dir,
                    label: {
                      content: this.carLsit[i].carNum,
                      offset: [-43, 30],
                    },
                  });
                  this.map.add(marker);
                } else {
                  let marker = new AMap.Marker({
                    icon: new AMap.Icon({
                      image: carred,
                      imageSize: new AMap.Size(25, 53),
                    }),
                    position: [this.carLsit[i].lng, this.carLsit[i].lat],
                    draggable: false,
                    alt: this.carLsit[i].deviceId,
                    angle: this.carLsit[i].dir,
                    label: {
                      content: this.carLsit[i].carNum,
                      offset: [-43, 30],
                    },
                  });
                  this.map.add(marker);
                }
              } else {
                let marker = new AMap.Marker({
                  icon: new AMap.Icon({
                    image: cargrey,
                    imageSize: new AMap.Size(25, 53),
                  }),
                  position: [this.carLsit[i].lng, this.carLsit[i].lat],
                  draggable: false,
                  alt: "this.carLsit[i].deviceId",
                  angle: this.carLsit[i].dir,
                  label: {
                    content: this.carLsit[i].carNum,
                    offset: [-43, 30],
                  },
                });
                this.map.add(marker);
              }
            }
          }
          // console.log("this.list", this.list);

          for (let i = 0; i < this.list.length; i++) {
            if (this.list[i].hand_movement == 1) {
              // 绿色
              if (this.list[i].point_full_status == 1) {
                _imgsrc = lvse;
              }

              // 黄色
              if (this.list[i].point_full_status == 2) {
                _imgsrc = huangse;
              }
              // 红色
              if (this.list[i].point_full_status == 3) {
                _imgsrc = hongse;
              }
            } else {
              // 红色
              if (this.list[i].barrel_full_info.barrel_full > 0) {
                _imgsrc = hongse;
              } // 黄色
              else if (this.list[i].barrel_full_info.barrel_no_full > 0) {
                _imgsrc = huangse;
              }

              // 绿色
              else if (this.list[i].barrel_full_info.barrel_no_full == 0) {
                _imgsrc = lvse;
              }
            }

            if (this.list[i].temperature_warning == 1) _imgsrc = hongse_2;
            let marker = new AMap.Marker({
              content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${_imgsrc}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
              position: [this.list[i].longitude, this.list[i].latitude],
              draggable: false,
              alt: this.list[i].point_id,
            });
            marker._point_id = this.list[i].point_id;

            this.markerarr.push(marker);
            marker.on("click", this.markerClick);
            marker.on("dblclick", this.markerdblClick);

            this.map.add(marker);
          }
        })
        .catch((e) => {
          console.log("错误----------->", e);
        });
    },
    //生成信息窗体
    async markerClick(payload) {
      // console.log(payload.target._originOpts.alt)
      let information;
      let content;
      this.list.forEach((item) => {
        if (item.point_id == payload.target._originOpts.alt) {
          information = item;
        }
      });
      // console.log("information", information);
      await AMap.plugin("AMap.Geocoder", () => {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: "010",
        });
        var lnglat = [information.longitude, information.latitude];

        geocoder.getAddress(lnglat, (status, result) => {
          if (status === "complete" && result.info === "OK") {
            // result为对应的地理位置详细信息

            this.address = result.regeocode.formattedAddress.split("梧州市")[1];
            console.log("result------------------>", result);
            if (this.width1 > 7000) {
              content = `<div style="width: 412px; height:298px;position: relative;">
            <p style="text-align: center;font-size: 26px;margin-bottom: 10px;padding-top: 6px;">基本信息</p>
            <p style="font-size: 26px;margin: 14px 10px;"><span style="color: #00fef7;">桶位名称:</span><span
                    style="color: #fff;margin-left: 3px;">${information.point_name}</span></p>
            <p style="font-size: 26px;margin: 14px 10px;"><span style="color: #00fef7;">所在位置:</span><span
                    style="color: #fff; margin-left: 3px;">${this.address}</span>
            </p>
            <p style="font-size: 26px;margin: 14px 10px;"><span style="color: #00fef7;">设备配置:</span><span
                    style="color: #fff;margin-left: 3px;">测满</span></p>
            <p style="font-size: 26px;margin: 14px 10px;"><span style="color: #00fef7;">设备状态:</span><span
                    style="color: #fff;margin-left: 3px;">正常</span></p>
                    <img style="position: absolute;right:20px;top:20px;width:30px;height:30px; cursor: pointer;"
                    src="${shexiangtou}"
                    onclick="handleMarkerEvent('${information.terminal_code}')"
                    ></img>
            <img style="position: absolute;z-index: -1;top: 0;" src="${InformationFormBgcBig}">
        </div>`;
            } else {
              content = `<div style="width: 206px; height:149px;position: relative;">
            <p style="text-align: center;font-size: 13px;margin-bottom: 5px;padding-top: 3px;">基本信息</p>
            <p style="font-size: 13px;margin: 8px 10px;"><span style="color: #00fef7;">桶位名称:</span><span
                    style="color: #fff;margin-left: 3px;">${
                      information.point_name
                    }</span></p>
            <p style="font-size: 13px;margin: 8px 10px;"><span style="color: #00fef7;">所在位置:</span><span
                    style="color: #fff; margin-left: 3px;">${
                      this.address
                    }</span>
            </p>
            <p style="font-size: 13px;margin: 8px 10px;"><span style="color: #00fef7;">设备配置:</span><span
                    style="color: #fff;margin-left: 3px;">测满</span></p>
            <p style="font-size: 13px;margin: 8px 10px;"><span style="color: #00fef7;">设备状态:</span><span
                    style="color: #fff;margin-left: 3px;">${
                      information.work_status == 0 ? "故障" : "正常"
                    }</span></p>
                    <img style="position: absolute;right:20px;top:20px;width:30px;height:30px; cursor: pointer;"
                    onclick="handleMarkerEvent('${information.terminal_code}')"
                    src="${shexiangtou}"
                    ></img>
            <img style="position: absolute;z-index: -1;top: 0;" src="${InformationFormBgc}">
            </div>`;
            }

            this.infoWindow = new AMap.InfoWindow({
              isCustom: true,
              content,
              offset: [30, -5],
            });
            this.infoWindow.open(this.map, payload.target._position);
          }
        });
      });
    },

    //双击修改地埋桶定位
    markerdblClick(payload) {
      console.log(payload.lnglat.kT);
      this.$confirm("确认修改该投放点定位？")
        .then(async () => {
          let res = await addEditPoint({
            point_id: payload.target._originOpts.alt,
            longitude: payload.lnglat.KL,
            latitude: payload.lnglat.kT,
          });
          this.$message({
            message: "操作成功",
            type: "success",
          });
        })
        .catch(() => {});
    },
    //关闭信息窗体
    closeinformation(a) {
      // console.log(a);
      this.infoWindow.close();
    },
    //监听地图等级缩放标记
    getzoom() {
      console.log(this.map.getZoom());

      return;
      // let a = 1 + (this.map.getZoom() - 14) / 5;
      // let width1;
      // let height1;
      // let size;
      // if (this.width1 > 7000) {
      //   width1 = a * 70;
      //   height1 = a * 70;
      //   size = 24;
      // } else {
      //   width1 = a * 35;
      //   height1 = a * 35;
      //   size = 12;
      // }
      // clearTimeout(this.time);
      // this.time = null;
      // if (this.map.getZoom() < 12.7) {
      //   width1 = 18;
      //   height1 = 18;
      //   this.time = setTimeout(() => {
      //     for (let i = 0; i < this.list.length; i++) {
      //       this.map.remove(this.markerarr[i]);
      //     }
      //     this.markerarr = [];
      //     for (let i = 0; i < this.list.length; i++) {
      //       if (this.list[i].barrel_full_info.barrel_full > 0) {
      //         let marker = new AMap.Marker({
      //           content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${hongse}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
      //           position: [this.list[i].longitude, this.list[i].latitude],
      //           draggable: true,
      //           alt: this.list[i].point_id,
      //         });
      //         this.markerarr.push(marker);
      //         marker.on("click", this.markerClick);
      //         marker.on("dblclick", this.markerdblClick);
      //         this.map.add(marker);
      //       } else {
      //         if (this.list[i].barrel_full_info.barrel_no_full > 0) {
      //           let marker = new AMap.Marker({
      //             content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${huangse}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
      //             position: [this.list[i].longitude, this.list[i].latitude],
      //             draggable: true,
      //             alt: this.list[i].point_id,
      //           });
      //           this.markerarr.push(marker);
      //           marker.on("click", this.markerClick);
      //           marker.on("dblclick", this.markerdblClick);
      //           this.map.add(marker);
      //         } else {
      //           let marker = new AMap.Marker({
      //             content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${lvse}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
      //             position: [this.list[i].longitude, this.list[i].latitude],
      //             draggable: true,
      //             alt: this.list[i].point_id,
      //           });
      //           this.markerarr.push(marker);
      //           marker.on("click", this.markerClick);
      //           marker.on("dblclick", this.markerdblClick);
      //           this.map.add(marker);
      //         }
      //       }
      //     }
      //   }, 300);
      // } else {
      //   this.time = setTimeout(() => {
      //     for (let i = 0; i < this.list.length; i++) {
      //       this.map.remove(this.markerarr[i]);
      //     }
      //     this.markerarr = [];
      //     for (let i = 0; i < this.list.length; i++) {
      //       if (this.list[i].barrel_full_info.barrel_full > 0) {
      //         let marker = new AMap.Marker({
      //           content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${hongse}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
      //           position: [this.list[i].longitude, this.list[i].latitude],
      //           draggable: true,
      //           alt: this.list[i].point_id,
      //         });
      //         this.markerarr.push(marker);
      //         marker.on("click", this.markerClick);
      //         marker.on("dblclick", this.markerdblClick);
      //         this.map.add(marker);
      //       } else {
      //         if (this.list[i].barrel_full_info.barrel_no_full > 0) {
      //           let marker = new AMap.Marker({
      //             content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${huangse}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
      //             position: [this.list[i].longitude, this.list[i].latitude],
      //             draggable: true,
      //             alt: this.list[i].point_id,
      //           });
      //           this.markerarr.push(marker);
      //           marker.on("click", this.markerClick);
      //           marker.on("dblclick", this.markerdblClick);
      //           this.map.add(marker);
      //         } else {
      //           let marker = new AMap.Marker({
      //             content: `<p style="text-align: center;"><img style="width: ${width1}px;height:${height1}px" src="${lvse}" alt=""></p><p style="color:white;font-size:${size}px;width:60px;    font-weight: 700;text-align: center;">${this.list[i].point_name}</p>`,
      //             position: [this.list[i].longitude, this.list[i].latitude],
      //             draggable: true,
      //             alt: this.list[i].point_id,
      //           });
      //           this.markerarr.push(marker);
      //           marker.on("click", this.markerClick);
      //           marker.on("dblclick", this.markerdblClick);
      //           this.map.add(marker);
      //         }
      //       }
      //     }
      //   }, 300);
      // }
    },
    async getAccessTokenValue() {
      const res = await getYunAccessToken();

      if (res.code == 1) {
        this._AccessToken = res.data;
      }
    },
  },
  created() {
    this.getAccessTokenValue();
    window.handleMarkerEvent = async (terminal_code) => {
      if (!terminal_code) {
        this.$message.error("未绑定监控设备!");
        return;
      }

      this.monitorPupShow = true;

      console.log({
        terminal_code,
        accessToken: this._AccessToken,
      });

      this.getPlayerUrl(terminal_code);

      // this.$router.push({
      //   path: "/ezopenIframe",
      //   query: {
      //     terminal_code,
      //     accessToken: this._AccessToken,
      //   },
      // });

      // window.open("https://open.ys7.com/ezopen/h5/live?" + instruct);
    };
  },

  beforeDestroy() {
    if (window.f5cc || window.window.f5cc != null) {
      clearInterval(window.f5cc);
    }
  },
  computed: {},

  watch: {},
};
</script>

<style lang="less" scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100vw;
  height: 100vh;
}

.player_wrapper {
  width: 700px;
  height: 500px;
  background-color: pink;
}
</style>
